import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isNil } from 'lodash'
import { flashErrorMessage } from 'redux-flash'
import { selectors as memberSelectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import { SectionHeader } from 'components'
import { Button } from 'lp-components'
import { TrailerCareAddOnButton } from 'member-portal/components'
import { TrailerCareErrorModal } from '../components'
import { PATH, makeMembershipIdPath } from 'config'
import {
  MembershipType,
  ProductPrices,
  MEMBERSHIP_TYPES_WITH_TRAILER_CARE,
  TRAILER_CARE_BASIC_TO_PLUS_UPGRADE,
  TRAILER_CARE_DETAIL,
  TRAILER_CARE_TYPE,
  TRAILER_CARE_TYPE_NO_UPGRADE,
} from 'types'
import { formatCurrency } from 'utils'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
  selectedTrailerCareType: PropTypes.string,
  setSelectedTrailerCareType: PropTypes.func.isRequired,
  trailerCarePrices: ProductPrices.isRequired,
}

function TrailerCareUpgradeOptions({
  flashErrorMessage,
  membership,
  trailerCarePrices,
  selectedTrailerCareType,
  setSelectedTrailerCareType,
}) {
  const history = useHistory()
  const { sfid: membershipId, trailer_care_type__c: currentTrailerCareType } =
    membership

  return (
    <section className="form-block-container">
      <header className="header-title">
        <h1>Upgrade Trailer Care</h1>
      </header>
      <div className="card card-medium">
        <div className="card-inner">
          <div className="form-block">
            <SectionHeader sectionHeaderName="Trailer Care Add-On" />
            {currentTrailerCareType &&
              <p>
                You already have {currentTrailerCareType} ($
                {trailerCarePrices[currentTrailerCareType]}/year) with your Sea
                Tow membership. Want to increase your Trailer Care coverage?
              </p>
            }

            <AvailableUpgradeOptions
              {...{
                membership,
                selectedTrailerCareType,
                setSelectedTrailerCareType,
                trailerCarePrices,
              }}
            />
            <div className="button-group">
              <Button
                onClick={() => {
                  if (
                    isNil(selectedTrailerCareType) ||
                    selectedTrailerCareType === TRAILER_CARE_TYPE_NO_UPGRADE ||
                    selectedTrailerCareType === currentTrailerCareType
                  ) {
                    flashErrorMessage(
                      'Please select a trailer care upgrade option',
                    )
                  } else {
                    history.push(
                      makeMembershipIdPath(
                        membershipId,
                        PATH.UPGRADES,
                        PATH.TRAILER_CARE,
                        PATH.PAYMENT,
                      ),
                    )
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

TrailerCareUpgradeOptions.propTypes = propTypes

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashErrorMessage,
  setSelectedTrailerCareType: memberActions.setSelectedTrailerCareType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrailerCareUpgradeOptions,
)

function AvailableUpgradeOptions({
  membership,
  selectedTrailerCareType,
  setSelectedTrailerCareType,
  trailerCarePrices,
}) {
  const {
    membership_type__c: membershipType,
    trailer_care_type__c: currentTrailerCareType,
  } = membership

  if (!MEMBERSHIP_TYPES_WITH_TRAILER_CARE.includes(membershipType)) {
    return (
      <TrailerCareErrorModal errorMessage="Unfortunately, Trailer Care isn't available for your membership type" />
    )
  }

  if (currentTrailerCareType === TRAILER_CARE_TYPE.PLUS) {
    return (
      <TrailerCareErrorModal errorMessage="You already have Trailer Care Plus for this membership" />
    )
  }

  // Determine if we are *adding* trailer care versus *upgrading* existing
  // trailer care. The options presented to the user are different for each
  // scenario...
  const upgradeOptions = isNil(currentTrailerCareType)
    ? Object.values(TRAILER_CARE_TYPE)
    : [TRAILER_CARE_BASIC_TO_PLUS_UPGRADE]

  return (
    <TrailerCareOptions
      options={upgradeOptions}
      {...{
        selectedTrailerCareType,
        setSelectedTrailerCareType,
        trailerCarePrices,
      }}
    />
  )
}

function TrailerCareOptions({
  options,
  selectedTrailerCareType,
  setSelectedTrailerCareType,
  trailerCarePrices,
}) {
  return (
    <div className="flex-vertical add-on-buttons">
      {options.map((type) => {
        const details = TRAILER_CARE_DETAIL[type]
        const isUpgrade = type === TRAILER_CARE_BASIC_TO_PLUS_UPGRADE

        return (
          <TrailerCareAddOnButton
            key={type}
            trailerCareType={details.marketingTitle}
            isSelected={type === selectedTrailerCareType}
            handleClick={() => setSelectedTrailerCareType(type)}
          >
            <div className="product-details">
              <span className="radio"></span>
              <div className="text-block">
                <h3>{details?.marketingTitle || type}</h3>
                <ul>
                  <li>
                    <em>
                      Up to {formatCurrency(details.coverageLimit, 0)} per
                      incident
                      {isUpgrade
                        && ` ($${trailerCarePrices[TRAILER_CARE_TYPE.PLUS]} annual plan)`
                      }
                    </em>
                  </li>
                </ul>
              </div>
              <div className="price-block">
                <p className="price">
                  <span>
                    {isUpgrade && "+"}{formatCurrency(trailerCarePrices[type])}
                  </span>
                  <span className="time">
                    {isUpgrade ? " upgrade" : " / Year"}
                  </span>
                </p>
              </div>
            </div>
          </TrailerCareAddOnButton>
        )
      })}
    </div>
  )
}
