import React from 'react'
import PropTypes from 'prop-types'
import { TRAILER_CARE_DETAIL, TRAILER_CARE_TYPES, ProductPrices } from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { TrailerCareAddOnButton } from 'member-portal/components'
import { formatCurrency } from 'utils'

const propTypes = {
  trailerCarePrices: ProductPrices.isRequired,
  setSelectedTrailerCareType: PropTypes.func.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
}

function TrailerCareAddOn({
  trailerCarePrices,
  selectedTrailerCareType,
  setSelectedTrailerCareType,
}) {
  return (
    <div className="group-block">
      <h3 className="group-title">Membership Upgrade</h3>
      <p>Upgrade your membership with Trailer Care<sup>®</sup> to provide 24-hour roadside assistance for your trailer and towing vehicle. Services can include towing of the trailer, flat tire changes, jump starts, fuel delivery, lockout help, and more. Upgrade now to meet all your boating needs on the water and off.</p>
      <div className="flex-vertical add-on-buttons">
        {TRAILER_CARE_TYPES.map((type) => {
          const details = TRAILER_CARE_DETAIL[type]
          return (
            <TrailerCareAddOnButton
              key={type}
              trailerCareType={details?.marketingTitle || type}
              isSelected={type === selectedTrailerCareType}
              handleClick={() => setSelectedTrailerCareType(type)}
            >
              <div className="product-details">
                <span className="radio"></span>
                <div className="text-block">
                  <h3>{details?.marketingTitle || type}</h3>
                  {details && (
                  <ul>
                    <li><em>Coverage up to {formatCurrency(details.coverageLimit, 0)} per
                    incident</em></li>
                  </ul>
                  )}
                </div>
                {details && (
                <div className="price-block">
                  <p className="price">
                    <span>{formatCurrency(trailerCarePrices[type])}</span>
                    <span className="time"> / Year</span>
                  </p>
                </div>
                )}
              </div>
            </TrailerCareAddOnButton>
          )
        })}
      </div>
    </div>
  )
}

TrailerCareAddOn.propTypes = exact(propTypes)

export default pure(TrailerCareAddOn)
