import React from 'react'
import PropTypes from 'prop-types'
import { COST, SHIPPING_COST } from 'config'
import {
  AppliedDiscountCodeType,
  DISCOUNT_CODE_TYPE_HEADERS,
  DISCOUNT_CODE_TYPE,
  MEMBERSHIP_DETAIL,
  MembershipType,
  ProductPrices,
  TRAILER_CARE_BASIC_TO_PLUS_UPGRADE,
  TRAILER_CARE_DETAIL,
  TRAILER_CARE_TYPE_NO_UPGRADE,
  TRAILER_CARE_TYPE,
} from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { Link } from 'react-router-dom'
import {
  formatCurrency,
  useToggle,
  calculateDiscountedCost,
  calculateDiscountDollarsOff,
  calculateReferralCreditsUsed,
} from 'utils'

const propTypes = {
  discountCodeDetails: AppliedDiscountCodeType,
  donation: PropTypes.number,
  giftWrapped: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  membershipPrices: ProductPrices,
  membershipToRenew: MembershipType,
  membershipType: PropTypes.string,
  promoCodeIsTrial: PropTypes.bool,
  referralCredits: PropTypes.number,
  shippingMethod: PropTypes.string,
  trailerCarePrices: ProductPrices,
  trailerCareType: PropTypes.string,
  trialDuration: PropTypes.number,
  updateLink: PropTypes.string,
}

function MembershipSummary({
  discountCodeDetails,
  donation,
  giftWrapped,
  isTrialMembershipsActive,
  membershipPrices,
  membershipToRenew,
  membershipType,
  promoCodeIsTrial,
  referralCredits = 0,
  shippingMethod,
  trailerCarePrices,
  trailerCareType,
  trialDuration,
  updateLink,
}) {
  const [expanded, toggleExpanded] = useToggle(false)
  let total = 0

  // Membership cost...
  let originalMembershipCost = 0
  let membershipCost = 0
  let membershipDetails
  let referralCreditsApplied = 0

  if (membershipType) {
    membershipDetails = MEMBERSHIP_DETAIL[membershipType]
    originalMembershipCost =
      isTrialMembershipsActive && promoCodeIsTrial
        ? 0
        : membershipPrices[membershipType]

    membershipCost = calculateDiscountedCost(
      originalMembershipCost,
      discountCodeDetails
    )

    referralCreditsApplied = calculateReferralCreditsUsed(
      membershipCost,
      referralCredits
    )

    if (referralCreditsApplied >= membershipCost) total = 0
    else total += membershipCost - referralCreditsApplied
  }

  // Trailer care cost...
  let trailerCareCost = 0
  let trailerCareDetails
  if (trailerCareType && trailerCareType !== TRAILER_CARE_TYPE_NO_UPGRADE) {
    trailerCareDetails = TRAILER_CARE_DETAIL[trailerCareType]
    trailerCareCost = trailerCarePrices[trailerCareType]

    total += trailerCareCost
  }

  // Donation...
  let donationAmount = 0
  if (donation && donation > 0) {
    donationAmount += donation

    total += donation
  }

  // Shipping and handling...
  let shippingCost = 0
  if (shippingMethod) {
    shippingCost = SHIPPING_COST[shippingMethod]

    total += shippingCost
  }

  // Gift wrapping...
  let giftWrappingCost = 0
  if (giftWrapped) {
    giftWrappingCost = COST.GIFT_WRAPPING

    total += giftWrappingCost
  }

  return (
    <div className="membership-summary">
      <div className="card">
        <header>
          <h4>My Cart</h4>
          <button
            type="button"
            onClick={toggleExpanded}
            aria-controls="membership-summary-details"
            aria-expanded={expanded}
            aria-label={`${expanded ? 'Hide' : 'Show'
              } membership summary details`}
            className="link-secondary"
          >
            <img src="/assets/images/icons/dropdown.svg" alt="" className="" />
          </button>
        </header>
        <div id="membership-summary-details" hidden={!expanded}>
          <div className="invoice-summary">
            {membershipType && (
              <InvoiceItemDetails
                title={
                  promoCodeIsTrial && isTrialMembershipsActive
                    ? `${membershipType} - ${trialDuration} days`
                    : `${membershipType} Membership`
                }
                description={
                  membershipDetails.coverage ||
                  membershipDetails.usedInConcise ||
                  membershipDetails.usedIn
                }
                cost={originalMembershipCost}
                discountedCost={
                  originalMembershipCost !== membershipCost &&
                  originalMembershipCost
                }
              />
            )}

            {/* If the Discount Code Details has a value and the Promo Code is Trial Memberships (and the Trial Memberships feature is set to active), then don't render anything. Otherwise as long as we have the Discount Code Details, then render the Invoice Item Details */}
            {discountCodeDetails && promoCodeIsTrial && isTrialMembershipsActive
              ? ''
              : discountCodeDetails && (
                <InvoiceItemDetails
                  title={
                    discountCodeDetails.type === DISCOUNT_CODE_TYPE.ACTIVATION
                      ? DISCOUNT_CODE_TYPE_HEADERS.ACTIVATION
                      : DISCOUNT_CODE_TYPE_HEADERS.PROMO
                  }
                  description={discountCodeDetails.description}
                  cost={calculateDiscountDollarsOff(
                    originalMembershipCost,
                    discountCodeDetails
                  )}
                  isDiscountCode
                />
              )}

            {membershipToRenew && referralCredits > 0 && (
              <InvoiceItemDetails
                title="Referral Credits Applied"
                cost={-referralCreditsApplied}
              />
            )}
            {trailerCareType && trailerCareType !== TRAILER_CARE_TYPE_NO_UPGRADE && (
              <InvoiceItemDetails
                title={trailerCareDetails.marketingTitle}
                description={`Up to ${formatCurrency(trailerCareDetails?.coverageLimit, 0)} per incident ${trailerCareType === TRAILER_CARE_BASIC_TO_PLUS_UPGRADE
                  ? ` ($${trailerCarePrices[TRAILER_CARE_TYPE.PLUS]} annual plan)`
                  : ''}`
                }
                cost={trailerCareCost}
              />
            )}
            {donationAmount > 0 && (
              <InvoiceItemDetails title="Donation" cost={donationAmount} />
            )}
            {shippingMethod && (
              <InvoiceItemDetails
                title="Shipping & Handling"
                cost={shippingCost}
              />
            )}
            {giftWrapped && (
              <InvoiceItemDetails
                title="Gift Wrapping"
                cost={giftWrappingCost}
              />
            )}

            <div className="invoice-item total">
              <p className="title">Total</p>
              <p className="cost">{formatCurrency(total)}</p>
            </div>
          </div>
        </div>
      </div>

      {/* If the Update Link has a value and the Promo Code is Trial Memberships (and the Trial Memberships feature is set to active), then don't render anything. Otherwise as long as we have the Update Link, then render the Link component */}
      {updateLink && promoCodeIsTrial && isTrialMembershipsActive
        ? ''
        : updateLink && (
          <Link to={updateLink} className="link-primary">
            Change Membership Selections
          </Link>
        )}
    </div>
  )
}

MembershipSummary.propTypes = propTypes

function mapStateToProps(state) {
  return {
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

export default compose(connect(mapStateToProps, null))(MembershipSummary)

function InvoiceItemDetails({
  title,
  description,
  cost,
  isDiscountCode = false,
}) {
  return (
    <div className="flex-vertical">
      <div className="invoice-item">
        <div>
          <p className="title">{title}</p>
          {description && (
            <span>
              <em>{description}</em>
            </span>
          )}
        </div>
        <div className="cost">
          <p className="cost">
            {`${isDiscountCode ? '- ' : ''}${formatCurrency(cost)}`}
          </p>
        </div>
      </div>
    </div>
  )
}
