import * as LS from 'local-storage'
import {
  MEMBERSHIP_TYPE,
  TRAILER_CARE_TYPE,
  TRAILER_CARE_TYPE_NO_UPGRADE,
} from 'types'

// If the membership type is not Gold or Lake, we always want to ensure
// the Trailer Care type is 'No Trailer Care'.
export default function getTrailerCareByMembershipType(
  membershipType,
  trailerCareType = TRAILER_CARE_TYPE.PLUS,
) {
  LS.clearSelectedTrailerCareType()
  
  return ![MEMBERSHIP_TYPE.GOLD_CARD, MEMBERSHIP_TYPE.LAKE_CARD].includes(
    membershipType,
  )
    ? TRAILER_CARE_TYPE_NO_UPGRADE
    : trailerCareType
}
