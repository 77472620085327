import PropTypes from 'prop-types'

export const ACCOUNT_DETAIL_TYPE = {
  CUSTOMER_RETAIL: 'Customer - Retail',
  CUSTOMER_BUSINESS: 'Customer - Business',
  CUSTOMER_MONTHLY: 'Customer - Monthly',
  CUSTOMER_SUBSIDIZED: 'Customer - Subsidized',
  FRANCHISEE: 'Franchisee',
  KITS_GIFT_CARD_RESELLER: 'Kits\\Gift Card Reseller',
  PARTNER: 'Partner',
  SAVINGS_CLUB: 'Savings Club',
  SEA_CARE_AND_TRIAL_ONLY: 'Sea Care and Trial Only',
  SHOW_EVENT: 'Show \\ Event',
}

export const isMemberAccountType = (detailType) =>
  detailType == ACCOUNT_DETAIL_TYPE.CUSTOMER_RETAIL ||
  detailType === ACCOUNT_DETAIL_TYPE.CUSTOMER_SUBSIDIZED ||
  detailType === ACCOUNT_DETAIL_TYPE.CUSTOMER_BUSINESS

export const isMemberBusinessAccountType = (detailType) =>
  detailType === ACCOUNT_DETAIL_TYPE.CUSTOMER_BUSINESS

export const ALL_OFFER_CATEGORIES = 'All'

const API_CALL_SEATOW_ERROR =
  'Looks like something went wrong. DO NOT ATTEMPT TO SUBMIT THE FORM AGAIN.  Please call 1-800-473-2869 (800-4-SEATOW) so we can check to see if your membership and payment did actually process.'
export const API_ERROR_MAP = {
  AUTHNET_ERROR_OCCURRED:
    'An error occurred during processing. Please try again. If you see this error multiple times, please call 1-800-473-2869 (800-4-SEATOW) so we can assist you.',
  AUTHNET_NULL_RESPONSE: API_CALL_SEATOW_ERROR,
  DUPLICATE_TRANSACTION_SUBMITTED:
    'Looks like your application got submitted more than once. We love that you want to be a Sea Tow member that much!<br/><br/>Most likely your membership payment went through, but please give our team a call at 800-473-2869 (800-4SEATOW) to verify your membership is ready to go for when you next head out on the water.<br/><br/>Thank you!',
  EXPIRED_CREDIT_CARD: 'The credit card number that was entered has expired.',
  ERROR_PROCESSING_PAYMENT: `There was a problem processing your payment.<br><br>Just like Sea Tow captains do when you call for assistance on the water, it's time to troubleshoot to see if we can get you back underway to membership.<br><br>First, please go back to the PAYMENT section to verify your credit card information and then re-submit.<br><br>If you continue to get this error message, give our team a call at 800-473-2869 (800-4SEATOW) so they can review your account and troubleshoot from their end. Thank you!`,
  INVALID_CODE_ON_MEMBERSHIP_TYPE:
    'Activation Code not valid on this membership type.  Please choose another membership type.',
  INVALID_CODE_ON_NEW_BUSINESS_TYPE:
    'Activation code not valid for new memberships.',
  INVALID_CODE_ON_RENEW_BUSINESS_TYPE:
    'Activation Code not valid for membership renewals.',
  INACTIVE_PROMO: 'The promotion code that was entered has expired.',
  INVALID_CREDIT_CARD_EXPIRATION_DATE:
    'The credit card expiration date is invalid.',
  INVALID_CREDIT_CARD_NUMBER:
    'The credit card number that was entered is invalid (the card number is incorrect or unknown).',
  INVALID_ID:
    'No active promotion or activation kit found with the provided code.',
  INVALID_NEW_ONLY: 'This promotion code is only for new members.',
  INVALID_RENEW_ONLY:
    'This promotion code is only for members renewing their membership.',
  MEMBER_EXPIRING_TOO_SOON:
    'Your membership will expire before your new card request can be fulfilled. Your request will not be processed.',
  NO_ACCOUNT_FOUND: API_CALL_SEATOW_ERROR,
  NO_ADDITIONAL_TRIAL_TIME_PROMOTION:
    // Note: this error message is purposefully vague to prevent the user from
    // knowing which promotion codes are time trials.
    'The promotion code that was entered is invalid.',
  PASSWORD_COMPLEXITY_ERROR:
    'The password provided does not meet the current complexity requirements.',
  PAYMENT_DECLINED: `Your credit card was declined. Just like Sea Tow captains do when you call for assistance on the water, it's time to troubleshoot to see if we can get you back underway to membership.<br><brFirst, please go back to the PAYMENT section to verify your credit card information, or use a different credit card, and then re-submit.<br><brIf you continue to get this error message, give our team a call at 800-473-2869 (800-4SEATOW) so they can review your account and troubleshoot from their end. Thank you!
  `,
  PRICE_MISMATCH:
    'There was an internal error processing your membership purchase and your order could not be completed. Please call 800-4-SEATOW (800-473-2869) to join instead.',
  REQUIRED_FIELD_MISSING: API_CALL_SEATOW_ERROR,
  TOO_MANY_REFERRAL_CREDITS_PASSED: API_CALL_SEATOW_ERROR,
  UNKNOWN_ERROR:
    'Something unexpected went wrong with processing your application and payment.<br/><br/>Just like you would call us when something unexpected happens on the water, please reach out to our team at 800-473-2869 (800-4SEATOW) so they can take a look at your account and get you back underway to membership!',
}

// Map Sea Tow Management API keys to the "friendlier" keys used in forms.
// Each property is the Sea Tow Management API key; the value is the name of
// the corresponding form key (form 'name' property).
export const API_KEY_MAP = {
  ACCOUNT_KEY_MAP: {
    account_detail_type__c: 'accountDetailType',
    name: 'businessName',
    shippingstreet: 'streetAddress',
    shippingcity: 'city',
    shippingstate: 'state',
    shippingpostalcode: 'postalCode',
    shippingcountry: 'country',
  },
  BILLING_ADDRESS_KEYMAP: {
    billingstreet: 'address',
    billingcity: 'city',
    billingstate: 'state',
    billingpostalcode: 'postalCode',
    billingcountry: 'country',
  },
  BOAT_KEYMAP: {
    address__c: 'address',
    aor__c: 'aor',
    boat_make__c: 'boatMake',
    color__c: 'boatColor',
    engine_type__c: 'engineType',
    fuel_type__c: 'fuelType',
    geolocation2__latitude__s: 'lat',
    geolocation2__longitude__s: 'lng',
    home_port_city__c: 'city',
    home_port_state__c: 'state',
    home_port_country__c: 'country',
    home_port_type__c: 'homePortType',
    marina_name__c: 'homePortName',
    hull_type__c: 'hullType',
    length__c: 'length',
    name__c: 'boatName',
    number_of_engines__c: 'numberOfEngines',
    primary_boat__c: 'primaryBoat',
    registration_document__c: 'registrationNumber',
    year__c: 'year',
  },
  BUSINESS_INFORMATION_KEYMAP: {
    billingcity: 'mailingCity',
    billingcountry: 'mailingCountry',
    billingpostalcode: 'mailingPostalCode',
    billingstate: 'mailingState',
    billingstreet: 'mailingAddress',
    company_logo__c: 'logoUrl',
    more_than_one_location__c: 'multipleLocation',
    name: 'businessName',
    partner_description__c: 'description',
    partner_facebook__c: 'facebook',
    partner_instagram__c: 'instagram',
    partner_linkedin__c: 'linkedIn',
    phone: 'phone',
    public_facing_email_address__c: 'publicEmail',
    public_facing_url__c: 'websiteUrl',
    services_requested__c: 'servicesRequested',
    shippingcity: 'publicCity',
    shippingcountry: 'publicCountry',
    shippingpostalcode: 'publicPostalCode',
    shippingstate: 'publicState',
    shippingstreet: 'publicAddress',
  },
  CONTACT_KEY_MAP: {
    contact_type__c: 'relationship',
    email: 'email',
    firstname: 'firstName',
    lastname: 'lastName',
    mobile_push_notification_opt_in__c: 'mobilePushOptIn',
    mobilephone: 'mobilePhone',
    phone: 'phone',
    sms_opt_in__c: 'smsOptIn',
  },
  CARD_REPRINT_MAP: {
    reason_for_request__c: 'cardReason',
  },
  CREDIT_CARD_KEY_MAP: {
    card_number__c: 'cardNumber',
    card_expiration_date__c: 'cardExpirationDate',
    card_security_code__c: 'securityCode',
  },
  GIFT_LOGISTICS_KEY_MAP: {
    distribution_method__c: 'deliveryMethod',
    gift_message__c: 'giftMessage',
    gift_wrap__c: 'giftWrapped',
    shipping_method__c: 'shippingMethod',
    value__c: 'totalCost',
  },
  GIFT_BILLING_KEY_MAP: {
    city__c: 'city',
    country__c: 'country',
    email__c: 'email',
    first_name__c: 'firstName',
    last_name__c: 'lastName',
    phone__c: 'phone',
    postal_code__c: 'postalCode',
    state__c: 'state',
    street__c: 'address',
  },
  GIFT_CREDIT_CARD_KEY_MAP: {
    card_expiration_date: 'cardExpirationDate',
    card_number: 'cardNumber',
    security_code: 'securityCode',
  },
  GIFT_RECIPIENT_KEY_MAP: {
    recipient_city__c: 'city',
    recipient_country__c: 'country',
    recipient_email__c: 'email',
    recipient_first_name__c: 'firstName',
    recipient_last_name__c: 'lastName',
    recipient_phone__c: 'phone',
    recipient_postal_code__c: 'postalCode',
    recipient_state__c: 'state',
    recipient_street__c: 'address',
  },
  OTHER_BOAT_KEYMAP: {
    aor__c: 'aor',
    address__c: 'address',
    geolocation2__latitude__s: 'lat',
    geolocation2__longitude__s: 'lng',
    home_port_city__c: 'city',
    home_port_country__c: 'country',
    home_port_state__c: 'state',
    home_port_type__c: 'homePortType',
    marina_name__c: 'homePortName',
    name_of_boat_club__c: 'boatClubName',
    no_boat_reason__c: 'noBoatReason',
    primary_boat__c: 'primaryBoat',
  },
  PROGRAM_APPLICATION_KEYMAP: {
    accountid: 'accountid',
    email: 'email',
    firstname: 'firstName',
    lastname: 'lastName',
    name: 'businessName',
    phone: 'phone',
    public_facing_url__c: 'websiteUrl',
    shippingcity: 'publicCity',
    shippingcountry: 'publicCountry',
    shippingpostalcode: 'publicPostalCode',
    shippingstate: 'publicState',
    shippingstreet: 'publicAddress',
  },
  REGISTRATION_KEY_MAP: {
    membership_number__c: 'membershipNumber',
    membership_expiration_date__c: 'membershipExpirationDate',
  },
  REFERRAL_KEY_MAP: {
    member_referred_email__c: 'email',
    member_referred_first_name__c: 'firstName',
    member_referred_last_name__c: 'lastName',
  },
  OFFER_KEY_MAP: {
    primary_category__c: 'primaryCategory',
    secondary_category__c: 'secondaryCategories',
    type_of_offer__c: 'offerType',
    dollars_off__c: 'dollarsOff',
    percent_off__c: 'percentageOff',
    type_of_offer_other__c: 'otherTypeDescription',
    offer_description__c: 'description',
    online_redemption__c: 'online',
    in_person_redemption__c: 'inPerson',
    options_for_online_redemption__c: 'onlineRedemptionOption',
    options_for_online_redemption_other__c: 'onlineRedemptionInstructions',
    options_for_in_store_redemption__c: 'inPersonRedemptionOption',
    options_for_in_store_redemption_other__c: 'inPersonRedemptionInstructions',
    online_promo_code__c: 'promoCode',
    online_promotional_url__c: 'websiteUrl',
    offer_status__c: 'offerStatus',
    partner_account__c: 'accountId',
    approval_status__c: 'approvalStatus',
  },
}

export const API_RESPONSE = {
  AUTH0_ACCOUNT_FOUND: 'AUTH0_ACCOUNT_FOUND',
  AUTH0_ACCOUNT_NOT_FOUND: 'AUTH0_ACCOUNT_NOT_FOUND',
  NO_AUTH0_CHECK_PERFORMED: 'NO_AUTH0_CHECK_PERFORMED',
}

export const APPROVAL_STATUS = {
  APPROVED: 'Approved',
  AWAITING_APPROVAL: 'Awaiting Approval',
  AWAITING_EDIT_APPROVAL: 'Awaiting Edit Approval',
  DENIED: 'Denied',
}

export const BOAT_OWNERSHIP_TYPE = {
  MY_BOAT: 'I own a boat',
  OTHER_BOAT: 'Other (Boat Club, Rent/Charter)',
}

export const BOAT_STATUS = {
  ACTIVE: 'Active',
  DESTROYED: 'Destroyed',
  NOT_IN_USE: 'Not In Use',
  SOLD: 'Sold',
}

export const BOAT_USE_TYPE = {
  RECREATIONAL: 'Recreational',
  COMMERCIAL: 'Commercial',
}

export const BOAT_USE_TYPE_NONE = 'No use type'

export const COMMUNICATION_PREFERENCES = {
  BENEFITS_AND_OFFERS: 'Benefits and Offers',
  DO_NOT_CALL: 'Do Not Call',
  DO_NOT_EMAIL: 'Do Not Email',
  DO_NOT_MAIL: 'Do Not Mail',
  DO_NOT_RENEW: 'Do Not Renew',
  DO_NOT_SOLICIT: 'Do Not Solicit',
  LOCAL_CAPTAINS_CORNER: "Local Captain's Corner",
  PAPERLESS_RENEWAL: 'Paperless Renewal',
  SEATOW_NEWS: 'Sea Tow News',
  SMS_OPT_IN: 'SMS Opt-In',
}

export const CONTACT_MEMBER_RELATIONSHIPS = [
  'Boat Co-owner',
  'Spouse Partner',
  'Business Owner',
  'Sibling',
  'Child',
  'Other',
]

export const CONTACT_PARTNER_RELATIONSHIPS = [
  'Business Owner',
  'Employee',
  'Office Manager',
  'Other',
]

export const DISCOUNT_CODE_TYPE = {
  ACTIVATION: 'activation',
  PROMO: 'promotion',
}

export const DISCOUNT_CODE_TYPE_HEADERS = {
  ACTIVATION: 'Activation Code',
  PROMO: 'Promo Code',
}

export const DISCOUNT_CODE_AUTO_RENEW_REQUIRED = [
  'Ongoing with ARB',
  'One Time with ARB',
]

export const DONATION_CONTRIBUTION_AMOUNTS = {
  NO_DONATION: '$0',
  ONE_DOLLAR: '$1',
  FIVE_DOLLARS: '$5',
  TEN_DOLLARS: '$10',
  TWENTY_DOLLARS: '$20',
  FIFTY_DOLLARS: '$50',
  OTHER: 'Other',
}

export const EMAIL_COMMUNICATION_PREFERENCES = [
  'seaTowNews',
  'benefitsAndOffers',
  'localCaptainsCorner',
]

export const EMAIL_COMMUNICATION_PREFERENCES_SF_VALUES = {
  seaTowNews: COMMUNICATION_PREFERENCES.SEATOW_NEWS,
  benefitsAndOffers: COMMUNICATION_PREFERENCES.BENEFITS_AND_OFFERS,
  localCaptainsCorner: COMMUNICATION_PREFERENCES.LOCAL_CAPTAINS_CORNER,
}

export const ENGINE_TYPES = ['IO', 'Inboard', 'Outboard', 'Jet', 'Unspecified']
export const ENGINE_NUMBER = [...Array(5).keys()].slice(1) // 1..4 engines...

export const ERROR_STATUS = {
  API_DOWN: 'api-down',
  FORBIDDEN: '403',
  NETWORK_DOWN: 'network-down',
  NOT_FOUND: '404',
  UNAUTHORIZED: '401',
}

export const FUEL_TYPES = ['Diesel', 'Electric', 'Gasoline', 'Unspecified']

export const GIFT_DELIVERY_METHOD = {
  DIGITAL: 'Email',
  MAILED: 'Mail',
}

export const GIFT_DELIVERY_DESCRIPTION = {
  [GIFT_DELIVERY_METHOD.MAILED]:
    'Mailed Membership Packet (additional shipping and handling)',
  [GIFT_DELIVERY_METHOD.DIGITAL]: 'Digital Packet via Email',
}

export const GIFT_MAILING_OPTIONS = {
  TO_RECIPIENT: 'Mail to Recipient',
  TO_ME: 'Mail to Me',
}

export const HEROKU_ID_KEY = 'heroku_external_id__c'

export const HULL_TYPES = [
  'Aluminum',
  'Carbon Fiber',
  'Fiberglass',
  'Kevlar',
  'Steel',
  'Wood',
  'Other',
  'Unspecified',
]

export const HOME_PORT_TYPE = {
  MARINA: 'Marina',
  MOORING: 'Mooring',
  TRAILER: 'Trailer',
  HOME_DOCK: 'Home Dock',
  OTHER: 'Other',
}

export const LOGIN_STRATEGY = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google-oauth2',
  USERNAME_PASSWORD: 'auth0',
}

export const MEMBER_APPLICATION_QUESTION = {
  COMMERCIAL_OR_RECREATIONAL: 'commercial_or_recreational',
  LAKE: 'lake',
  PROFESSIONAL: 'professional',
  RECREATIONAL: 'recreational',
}

export const MEMBER_APPLICATION_FLOW = {
  NEW: 'New',
  RENEWAL: 'Renewal',
  MEMBERSHIP_UPGRADE: 'Membership Upgrade',
  TRAILER_CARE_UPGRADE: 'Trailer Care Upgrade',
}

export const MEMBERSHIP_CARD_REASONS = [
  'Lost',
  'Damaged',
  'Not received',
  'Duplicate',
  'Other',
]

export const MEMBERSHIP_STATUS = {
  ACTIVE: 'Active',
  CANCELLED: 'Cancelled',
  DO_NOT_RENEW: 'Do Not Renew',
  EXPIRED: 'Expired',
  FUTURE_START: 'Future Start',
  INACTIVE: 'Inactive',
  ON_HOLD: 'On Hold',
  PENDING_ACTIVATION: 'Pending Activation',
}

export const MEMBERSHIP_TYPE = {
  COMMERCIAL_CARD: 'Commercial Card',
  FLEET_CARD: 'Fleet Card',
  GOLD_CARD: 'Gold Card',
  LAKE_CARD: 'Lake Card',
  LAKE_FLEET_CARD: 'Lake Fleet Card',
  PROFESSIONAL_MARINER_CARD: 'Professional Mariner Card',
  TRIAL_MEMBERSHIP: 'Trial Membership',
}

export const MEMBERSHIP_LAKE_TO_GOLD_UPGRADE = 'Lake to Gold Upgrade'

export const MEMBERSHIP_TYPES_WITH_TRAILER_CARE = [
  MEMBERSHIP_TYPE.GOLD_CARD,
  MEMBERSHIP_TYPE.LAKE_CARD,
]

export const MEMBERSHIP_DETAIL = {
  [MEMBERSHIP_TYPE.COMMERCIAL_CARD]: {
    coverage: 'Single Vessel',
    boatUse: BOAT_USE_TYPE.COMMERCIAL,
    description:
      'If your boat is commercially registered or used in a commercial manner such as charters, rentals, a boat club, commercial fishing, diving, surveying, police, crew boat or as another working vessel; this is the membership for you.',
    image:
      '/assets/images/imagery/purchase-flow/membership-plans/commercial-card.jpg',
  },
  [MEMBERSHIP_TYPE.GOLD_CARD]: {
    usedIn: 'Salt and Fresh Water',
    boatUse: BOAT_USE_TYPE.RECREATIONAL,
    description:
      'The gold standard for on-water assistance at less than $21/month. Sea Tow Gold Card membership has the most comprehensive services available on the water at one annual rate with no fine print.',
    image:
      '/assets/images/imagery/purchase-flow/membership-plans/gold-card.jpg',
  },
  [MEMBERSHIP_TYPE.LAKE_CARD]: {
    usedIn: 'Fresh Water Only - Excludes AK, FL, HI, PR and VI',
    usedInConcise: 'Fresh Water Only',
    boatUse: BOAT_USE_TYPE.RECREATIONAL,
    description:
      '24/7 on-water assistance for $14 a month for all the boats you own + any you rent or borrow.  Lake Card membership provides services on inland freshwater, excluding the states of AK, FL, HI, PR, VI and rivers that flow to the coast.',
    image:
      '/assets/images/imagery/purchase-flow/membership-plans/lake-card.jpg',
  },
  [MEMBERSHIP_TYPE.PROFESSIONAL_MARINER_CARD]: {
    coverage: 'Individual on any vessel operating',
    boatUse: BOAT_USE_TYPE.COMMERCIAL,
    description:
      'For those that make their living on the water, Sea Tow’s Professional Mariner membership is a service package for on-water professionals who regularly use multiple vessels while performing their maritime duties. From yacht delivery captains to on-water instructors; any vessel the member is operating and is the master of, is entitled to receive membership benefits for that vessel.',
    image:
      '/assets/images/imagery/purchase-flow/membership-plans/mariner-card.jpg',
  },
  [MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP]: {
    usedIn: 'Salt and Fresh Water',
    boatUse: BOAT_USE_TYPE.RECREATIONAL,
    description: 'Trial Membership',
    image:
      '/assets/images/imagery/purchase-flow/membership-plans/gold-card.jpg',
  },
  [MEMBERSHIP_LAKE_TO_GOLD_UPGRADE]: {
    usedIn: 'Salt and Fresh Water',
    boatUse: BOAT_USE_TYPE_NONE,
    description:
      'Your current Lake Card member provides service only on inland freshwater, excluding the states of AK, FL, HI, PR, VI and rivers that flow to the coast.',
    image:
      '/assets/images/imagery/purchase-flow/membership-plans/gold-card.jpg',
  },
}

export const NOTIFICATION_CLASS = {
  DANGER: 'danger',
  DEFAULT: 'default',
  WARNING: 'warn',
}

export const OTHER_BOATING_OPTIONS = {
  BOAT_CLUB_MEMBER: 'Boat Club Member',
  RENT_OR_CHARTER: 'Rent/Charter',
  OTHER: 'Other',
}

export const PERMISSIONS = {
  MULTIPLE_BOATS: 'Multiple Boats',
}

export const PRIMARY_CONTACT = 'Primary Contact'

export const PRODUCT_PRICE_TYPE = {
  MEMBERSHIP: 'Membership',
  TRAILER_CARE: 'Trailer Care',
}

export const PROMO_MEMBER_APPLICATION_FLOW = {
  NEW: 'New',
  RENEW: 'Renew',
  REACQUIRED: 'Reacquired',
}

export const STATUS_CLASS = {
  NOT_OK: 'not-ok',
  OK: 'ok',
  WARNING: 'warning',
}

export const TRAILER_CARE_TYPE = {
  BASIC: 'Trailer Care',
  PLUS: 'Trailer Care Plus',
}

export const TRAILER_CARE_TYPE_NONE = 'No Trailer Care'
export const TRAILER_CARE_TYPE_NO_UPGRADE = 'Do not upgrade'
export const TRAILER_CARE_BASIC_TO_PLUS_UPGRADE = 'Trailer Care Plus Upgrade'

export const TRAILER_CARE_TYPES = [
  TRAILER_CARE_TYPE.PLUS,
  TRAILER_CARE_TYPE.BASIC,
  TRAILER_CARE_TYPE_NO_UPGRADE,
]

export const TRAILER_CARE_DETAIL = {
  [TRAILER_CARE_TYPE.BASIC]: {
    coverageLimit: 300.0,
    marketingTitle: 'Trailer Care',
  },
  [TRAILER_CARE_TYPE.PLUS]: {
    coverageLimit: 600.0,
    marketingTitle: 'Trailer Care＋',
  },
  [TRAILER_CARE_BASIC_TO_PLUS_UPGRADE]: {
    coverageLimit: 600.0,
    marketingTitle: 'Upgrade to Trailer Care＋',
  },
}

export const OFFER_CATEGORY = {
  APPAREL: 'Apparel',
  CHARTERS_RENTALS_AND_TRAVEL: 'Charters, Rentals & Travel',
  DINING: 'Dining',
  EDUCATION: 'Education',
  ELECTRONICS_AND_NAVIGATION: 'Electronics & Navigation',
  ENTERTAINMENT: 'Entertainment',
  FISHING: 'Fishing',
  FUEL: 'Fuel',
  EQUIPMENT_AND_SUPPLIES: 'Equipment & Supplies',
  MARINAS_DOCKAGE_AND_STORAGE: 'Marinas, Dockage & Storage',
  RETAIL: 'Retail',
  SAFETY: 'Safety',
  SERVICES_AND_REPAIR: 'Services & Repair',
  OTHER: 'Other',
}

export const OFFER_CATEGORY_ICON_PATH = {
  [OFFER_CATEGORY.APPAREL]:
    '/assets/images/icons/category-icons/cat-apparel.svg',
  [OFFER_CATEGORY.CHARTERS_RENTALS_AND_TRAVEL]:
    '/assets/images/icons/category-icons/cat-charters-rentals-travel.svg',
  [OFFER_CATEGORY.DINING]: '/assets/images/icons/category-icons/cat-dining.svg',
  [OFFER_CATEGORY.EDUCATION]:
    '/assets/images/icons/category-icons/cat-education.svg',
  [OFFER_CATEGORY.ELECTRONICS_AND_NAVIGATION]:
    '/assets/images/icons/category-icons/cat-electronics-navigation.svg',
  [OFFER_CATEGORY.ENTERTAINMENT]:
    '/assets/images/icons/category-icons/cat-entertainment.svg',
  [OFFER_CATEGORY.FISHING]:
    '/assets/images/icons/category-icons/cat-fishing.svg',
  [OFFER_CATEGORY.FUEL]: '/assets/images/icons/category-icons/cat-fuel.svg',
  [OFFER_CATEGORY.EQUIPMENT_AND_SUPPLIES]:
    '/assets/images/icons/category-icons/cat-equipment-supplies.svg',
  [OFFER_CATEGORY.MARINAS_DOCKAGE_AND_STORAGE]:
    '/assets/images/icons/category-icons/cat-marinas-dockage-storage.svg',
  [OFFER_CATEGORY.RETAIL]: '/assets/images/icons/category-icons/cat-retail.svg',
  [OFFER_CATEGORY.SAFETY]: '/assets/images/icons/category-icons/cat-safety.svg',
  [OFFER_CATEGORY.SERVICES_AND_REPAIR]:
    '/assets/images/icons/category-icons/cat-services-repair.svg',
  [OFFER_CATEGORY.OTHER]: '/assets/images/icons/category-icons/cat-other.svg',
}

export const OFFER_CATEGORY_IMAGE_PATH = {
  [OFFER_CATEGORY.APPAREL]:
    '/assets/images/imagery/category-images/cat-apparel.jpg',
  [OFFER_CATEGORY.CHARTERS_RENTALS_AND_TRAVEL]:
    '/assets/images/imagery/category-images/cat-charters-rentals-travel.jpg',
  [OFFER_CATEGORY.DINING]:
    '/assets/images/imagery/category-images/cat-dining.jpg',
  [OFFER_CATEGORY.EDUCATION]:
    '/assets/images/imagery/category-images/cat-education.jpg',
  [OFFER_CATEGORY.ELECTRONICS_AND_NAVIGATION]:
    '/assets/images/imagery/category-images/cat-electronics-navigation.jpg',
  [OFFER_CATEGORY.ENTERTAINMENT]:
    '/assets/images/imagery/category-images/cat-entertainment.jpg',
  [OFFER_CATEGORY.FISHING]:
    '/assets/images/imagery/category-images/cat-fishing.jpg',
  [OFFER_CATEGORY.FUEL]: '/assets/images/imagery/category-images/cat-fuel.jpg',
  [OFFER_CATEGORY.EQUIPMENT_AND_SUPPLIES]:
    '/assets/images/imagery/category-images/cat-equipment-supplies.jpg',
  [OFFER_CATEGORY.MARINAS_DOCKAGE_AND_STORAGE]:
    '/assets/images/imagery/category-images/cat-marinas-dockage-storage.jpg',
  [OFFER_CATEGORY.RETAIL]:
    '/assets/images/imagery/category-images/cat-retail.jpg',
  [OFFER_CATEGORY.SAFETY]:
    '/assets/images/imagery/category-images/cat-safety.jpg',
  [OFFER_CATEGORY.SERVICES_AND_REPAIR]:
    '/assets/images/imagery/category-images/cat-services-repair.jpg',
  [OFFER_CATEGORY.OTHER]:
    '/assets/images/imagery/category-images/cat-other.jpg',
}

export const OFFER_LOCALITY = {
  LOCAL: 'Local',
  NATIONAL: 'National',
}

export const OFFER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
}

export const OFFER_GROUP = {
  ACTIVE: 'active',
  PENDING: 'awaiting_approval',
  PRIOR: 'prior',
}

export const OFFER_TYPE = {
  DOLLARS_OFF: 'Dollars Off',
  PERCENTAGE_OFF: 'Percentage Off',
  OTHER: 'Other',
}

export const OFFER_REDEMPTION_TYPE = {
  ONLINE: 'Online',
  IN_PERSON: 'In-Person',
}

export const OFFER_ONLINE_REDEMPTION_OPTION = {
  PROMO_CODE: 'Promo Code',
  PROMO_URL: 'Promotional URL',
  REBATE: 'Rebate',
  OTHER: 'Other',
}

export const OFFER_IN_PERSON_REDEMPTION_OPTION = {
  MEMBERSHIP_CARD: 'Present Membership Card',
  REBATE: 'Rebate',
  OTHER: 'Other',
}

export const REDEMPTION_OPTIONS_WITH_DETAILS = [
  OFFER_ONLINE_REDEMPTION_OPTION.PROMO_CODE,
  OFFER_ONLINE_REDEMPTION_OPTION.OTHER,
  OFFER_IN_PERSON_REDEMPTION_OPTION.OTHER,
]

export const OFFER_EMPTY_STATE = {
  [OFFER_GROUP.ACTIVE]: {
    heading: 'No Active Deals',
    body: "You don't have any active deals right now.",
  },
  [OFFER_GROUP.PENDING]: {
    heading: 'No Deals Awaiting Approval',
    body: "You don't have any pending deals right now.",
  },
  [OFFER_GROUP.PRIOR]: {
    heading: 'No Prior Deals',
    body: "You don't have any prior deals right now.",
  },
}

export const PARTNER_PROGRAM = {
  MEMBERSHIP_KITS: 'Membership Kits',
  SAVINGS_CLUB: 'Savings Club',
  SEA_CARE: 'Sea Care',
}

export const PARTNER_PROGRAM_FIELDS = {
  membershipKits: PARTNER_PROGRAM.MEMBERSHIP_KITS,
  savingsClub: PARTNER_PROGRAM.SAVINGS_CLUB,
  seaCare: PARTNER_PROGRAM.SEA_CARE,
}

// --[ Prop Types ]------------------------------------------------------------
export const AccountType = PropTypes.shape({
  account_detail_type__c: PropTypes.string,
  approval_status__c: PropTypes.string,
  approved_services__c: PropTypes.string,
  billingcity: PropTypes.string,
  billingcountry: PropTypes.string,
  billingpostalcode: PropTypes.string,
  billingstate: PropTypes.string,
  billingstreet: PropTypes.string,
  company_logo__c: PropTypes.string,
  isdeleted: PropTypes.bool,
  more_than_one_location__c: PropTypes.bool,
  name: PropTypes.string,
  partner_description__c: PropTypes.string,
  partner_facebook__c: PropTypes.string,
  partner_instagram__c: PropTypes.string,
  partner_linkedin__c: PropTypes.string,
  partner_status__c: PropTypes.string,
  phone: PropTypes.string,
  primary_phone_type__c: PropTypes.string,
  public_facing_email_address__c: PropTypes.string,
  public_facing_url__c: PropTypes.string,
  recordtypeid: PropTypes.string,
  referral_credits__c: PropTypes.number,
  services_requested__c: PropTypes.string,
  shippingcity: PropTypes.string,
  shippingcountry: PropTypes.string,
  shippingpostalcode: PropTypes.string,
  shippingstate: PropTypes.string,
  shippingstreet: PropTypes.string,
  type: PropTypes.string,

  _hc_err: PropTypes.string,
  _hc_lastop: PropTypes.string,
  createddate: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  id: PropTypes.number,
  parentid: PropTypes.string,
  sfid: PropTypes.string,
  systemmodstamp: PropTypes.string,
})

export const ActivationCodeDetailsType = PropTypes.shape({
  card_status__c: PropTypes.string,
  is_test_kit__c: PropTypes.bool,
  name: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  isdeleted: PropTypes.bool,
  systemmodstamp: PropTypes.string,
  value__c: PropTypes.number,
  date_used__c: PropTypes.string,
  expire_date__c: PropTypes.string,
  createddate: PropTypes.string,
  isdonated__c: PropTypes.bool,
  sfid: PropTypes.string,
  id: PropTypes.number,
  _hc_lastop: PropTypes.string,
  _hc_err: PropTypes.string,
})

export const AorType = PropTypes.shape({
  active_owner__c: PropTypes.bool,
  alternate_24_hour_phone__c: PropTypes.string,
  aor_contact_phone__c: PropTypes.string,
  aor_description__c: PropTypes.string,
  aor_email__c: PropTypes.string,
  aor_home_page_url__c: PropTypes.string,
  aor_region_name__c: PropTypes.string,
  aor_sales_tax_rate__c: PropTypes.number,
  employee_photo_url__c: PropTypes.string,
  facebook__c: PropTypes.string,
  instagram__c: PropTypes.string,
  isdeleted: PropTypes.bool,
  linkedin__c: PropTypes.string,
  location__latitude__s: PropTypes.number,
  location__longitude__s: PropTypes.number,
  name: PropTypes.string,
  owner_contact__c: PropTypes.string,
  public_mailing_city__c: PropTypes.string,
  public_mailing_country__c: PropTypes.string,
  public_mailing_postal_code__c: PropTypes.string,
  public_mailing_state__c: PropTypes.string,
  public_mailing_street__c: PropTypes.string,
  serving_statement__c: PropTypes.string,
  status__c: PropTypes.string,
  systemmodstamp: PropTypes.string,
  x24_hour_phone__c: PropTypes.string,
  youtube__c: PropTypes.string,

  _hc_err: PropTypes.string,
  _hc_lastop: PropTypes.string,
  createddate: PropTypes.string,
  id: PropTypes.number,
  sfid: PropTypes.string,
})

export const AppliedDiscountCodeType = PropTypes.shape({
  autoRenewRequired: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  dollarsOff: PropTypes.number,
  percentOff: PropTypes.number,
  type: PropTypes.string,
})

export const ArbType = PropTypes.shape({
  id: PropTypes.string,
})

export const BillingType = PropTypes.shape({
  cardNumber: PropTypes.string,
  cardExpirationDate: PropTypes.string,
  securityCode: PropTypes.string,
  billingAddressSameAsShipping: PropTypes.bool,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  autoRenewalAgreement: PropTypes.bool,
  donationAmounts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  otherDollarDonationAmount: PropTypes.number,
})

export const BoatType = PropTypes.shape({
  account__c: PropTypes.string,
  address__c: PropTypes.string,
  aor__c: PropTypes.string,
  boat_make__c: PropTypes.string,
  boat_status__c: PropTypes.string,
  boat_transfer_type__c: PropTypes.string,
  city__c: PropTypes.string,
  color__c: PropTypes.string,
  contact__c: PropTypes.string,
  engine_type__c: PropTypes.string,
  franchisee_aor_link__c: PropTypes.string,
  fuel_type__c: PropTypes.string,
  geolocation2__latitude__s: PropTypes.number,
  geolocation2__longitude__s: PropTypes.number,
  hin__c: PropTypes.string,
  home_port_city__c: PropTypes.string,
  home_port_country__c: PropTypes.string,
  home_port_state__c: PropTypes.string,
  home_port_type__c: PropTypes.string,
  homeport_state__c: PropTypes.string,
  hull_type__c: PropTypes.string,
  isdeleted: PropTypes.bool,
  length__c: PropTypes.number,
  marina_name__c: PropTypes.string,
  name__c: PropTypes.string,
  name: PropTypes.string,
  name_of_boat_club__c: PropTypes.string,
  no_boat_reason__c: PropTypes.string,
  number_of_engines__c: PropTypes.string,
  primary_boat__c: PropTypes.bool,
  registration_document__c: PropTypes.string,
  related_membership__c: PropTypes.string,
  state__c: PropTypes.string,
  year__c: PropTypes.string,
  zip__c: PropTypes.string,

  _hc_err: PropTypes.string,
  _hc_lastop: PropTypes.string,
  account__r__heroku_external_id__c: PropTypes.string,
  contact__r__heroku_external_id__c: PropTypes.string,
  createddate: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  id: PropTypes.number,
  related_membership__r__heroku_external_id__c: PropTypes.string,
  sfid: PropTypes.string,
  systemmodstamp: PropTypes.string,
})

export const ContactType = PropTypes.shape({
  account_name__c: PropTypes.string,
  accountid: PropTypes.string,
  communication_preferences__c: PropTypes.string,
  contact_status__c: PropTypes.string,
  contact_type__c: PropTypes.string,
  email: PropTypes.string,
  firstname: PropTypes.string,
  homephone: PropTypes.string,
  incontact_phone_number__c: PropTypes.string,
  isdeleted: PropTypes.bool,
  lastname: PropTypes.string,
  mobile_incontact_number__c: PropTypes.string,
  mobilephone: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  recordtypeid: PropTypes.string,
  salutation: PropTypes.string,

  _hc_err: PropTypes.string,
  _hc_lastop: PropTypes.string,
  account__heroku_external_id__c: PropTypes.string,
  createddate: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  id: PropTypes.number,
  sfid: PropTypes.string,
  systemmodstamp: PropTypes.string,
})

export const ContactArrayType = PropTypes.arrayOf(ContactType)

export const CreditType = PropTypes.shape({
  cardNumber: PropTypes.string,
  cardExpirationDate: PropTypes.string,
  securityCode: PropTypes.string,
})

export const DigitalRecipientType = PropTypes.shape({
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
})

export const FulfillmentType = PropTypes.shape({
  account__c: PropTypes.string,
  account__r__heroku_external_id__c: PropTypes.string,
  boat__c: PropTypes.string,
  boat__r__heroku_external_id__c: PropTypes.string,
  fulfillment_code__c: PropTypes.string,
  invoice__c: PropTypes.string,
  isdeleted: PropTypes.bool,
  most_recent_date: PropTypes.string,
  name: PropTypes.string,
  order_date__c: PropTypes.string,
  processed_date__c: PropTypes.string,
  shipped_date__c: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,

  _hc_err: PropTypes.string,
  _hc_lastop: PropTypes.string,
  createddate: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  id: PropTypes.number,
  sfid: PropTypes.string,
  systemmodstamp: PropTypes.string,
})

export const FulfillmentArrayType = PropTypes.arrayOf(FulfillmentType)

export const GiftContactType = PropTypes.shape({
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
})

export const MailedRecipientContactType = PropTypes.shape({
  mailedToRecipient: PropTypes.bool,
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
})

export const MenuItemType = PropTypes.shape({
  icon: PropTypes.string,
  menuItemName: PropTypes.string,
  path: PropTypes.string,
})

export const PaymentProfileType = PropTypes.shape({
  account__c: PropTypes.string,
  account__r__heroku_external_id__c: PropTypes.string,
  auto_renew__c: PropTypes.bool,
  card_expiration_date__c: PropTypes.string,
  card_name__c: PropTypes.string,
  card_number__c: PropTypes.string,
  card_security_code__c: PropTypes.string,
  card_type__c: PropTypes.string,
  isdeleted: PropTypes.bool,
  last_4_digits__c: PropTypes.string,
  member_since__c: PropTypes.string,
  membership_contact__c: PropTypes.string,
  membership_contact__r__heroku_external_id__c: PropTypes.string,
  membership_expiration_date__c: PropTypes.string,
  membership_number__c: PropTypes.string,
  membership_status__c: PropTypes.string,
  membership_type__c: PropTypes.string,
  name: PropTypes.string,
  primary_boat__c: PropTypes.string,
  primary_membership__c: PropTypes.bool,
  referral_credit_amount__c: PropTypes.number,
  renewal_promo_dollar_value__c: PropTypes.number,
  renewal_promotion_code__c: PropTypes.string,
  trailer_care_type__c: PropTypes.string,

  _hc_err: PropTypes.string,
  _hc_lastop: PropTypes.string,
  createddate: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  id: PropTypes.number,
  sfid: PropTypes.string,
  systemmodstamp: PropTypes.string,
})

export const PromoCodeDetailsType = PropTypes.shape({
  promo_end_date__c: PropTypes.string,
  promo_standardized__c: PropTypes.string,
  valid_on_business_type__c: PropTypes.string,
  description__c: PropTypes.string,
  related_product__c: PropTypes.string,
  promo_start_date__c: PropTypes.string,
  name: PropTypes.string,
  title__c: PropTypes.string,
  promotion_type__c: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  isdeleted: PropTypes.bool,
  systemmodstamp: PropTypes.string,
  related_program__c: PropTypes.string,
  value_time_in_days_calculated__c: PropTypes.number,
  ongoing_promo_recurrences__c: PropTypes.number,
  ongoing_promo_type__c: PropTypes.string,
  promo_carries_on_renewal__c: PropTypes.bool,
  value_time_in_days__c: PropTypes.number,
  value_discount_in_dollars__c: PropTypes.number,
  createddate: PropTypes.string,
  promotion_details__c: PropTypes.string,
  value_discount_in_percentage__c: PropTypes.number,
  sfid: PropTypes.string,
  id: PropTypes.number,
  _hc_lastop: PropTypes.string,
  _hc_err: PropTypes.string,
})

export const MembershipType = PropTypes.shape({
  account__c: PropTypes.string,
  aor: AorType,
  auto_renew__c: PropTypes.bool,
  auto_renew_date: PropTypes.string,
  boats: PropTypes.arrayOf(BoatType),
  card_expiration_date__c: PropTypes.string,
  card_name__c: PropTypes.string,
  card_number__c: PropTypes.string,
  card_security_code__c: PropTypes.string,
  card_type__c: PropTypes.string,
  communication_member_renewal_amount__c: PropTypes.number,
  isdeleted: PropTypes.bool,
  last_4_digits__c: PropTypes.string,
  member_since__c: PropTypes.string,
  membership_contact__c: PropTypes.string,
  membership_effective_date__c: PropTypes.string,
  membership_expiration_date__c: PropTypes.string,
  membership_number__c: PropTypes.string,
  membership_status__c: PropTypes.string,
  membership_type__c: PropTypes.string,
  multiyear_product: PropTypes.bool,
  name: PropTypes.string,
  paymentProfile: PaymentProfileType,
  primary_boat__c: PropTypes.string,
  referral_credit_amount__c: PropTypes.number,
  renewal_promo_dollar_value__c: PropTypes.number,
  renewal_promotion_code__c: PropTypes.string,
  shopping_cart_membership_type__c: PropTypes.string,
  shopping_cart_trailer_care_type__c: PropTypes.string,
  trailer_care_type__c: PropTypes.string,

  _hc_err: PropTypes.string,
  _hc_lastop: PropTypes.string,
  account__r__heroku_external_id__c: PropTypes.string,
  createddate: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  id: PropTypes.number,
  membership_contact__r__heroku_external_id__c: PropTypes.string,
  sfid: PropTypes.string,
  systemmodstamp: PropTypes.string,
})

export const MembershipArrayType = PropTypes.arrayOf(MembershipType)

export const ReferralType = PropTypes.shape({
  referralId: PropTypes.string,
  fetchedReferrerMemberName: PropTypes.string,
  fetchedReferrerMemberNumber: PropTypes.string,
})

export const PasswordChangeTicketType = PropTypes.shape({
  ticket: PropTypes.string,
})

export const AllMembershipsType = PropTypes.shape({
  account: AccountType,
  memberships: MembershipArrayType,
})

export const QuickLinkType = PropTypes.shape({
  linkName: PropTypes.string,
  path: PropTypes.string,
})

export const SavingsClubOfferType = PropTypes.shape({
  approval_status__c: PropTypes.string,
  company_logo__c: PropTypes.string,
  company_name__c: PropTypes.string,
  distance_from_zip: PropTypes.number,
  dollars_off__c: PropTypes.number,
  featured__c: PropTypes.bool,
  id: PropTypes.number,
  in_person_redemption__c: PropTypes.bool,
  isdeleted: PropTypes.bool,
  locality_of_offer__c: PropTypes.string,
  name: PropTypes.string,
  offer_description__c: PropTypes.string,
  offer_status__c: PropTypes.string,
  online_promo_code__c: PropTypes.string,
  online_promotional_url__c: PropTypes.string,
  online_redemption__c: PropTypes.bool,
  options_for_in_store_redemption__c: PropTypes.string,
  options_for_online_redemption__c: PropTypes.string,
  ownerid: PropTypes.string,
  partner_account__c: PropTypes.string,
  percent_off__c: PropTypes.number,
  primary_category__c: PropTypes.string,
  secondary_category__c: PropTypes.string,
  sfid: PropTypes.string,
  type_of_offer__c: PropTypes.string,
  type_of_offer_other__c: PropTypes.string,

  _hc_err: PropTypes.string,
  _hc_lastop: PropTypes.string,
  createddate: PropTypes.string,
  heroku_external_id__c: PropTypes.string,
  systemmodstamp: PropTypes.string,
})

export const SavingClubOfferArrayType = PropTypes.arrayOf(SavingsClubOfferType)
export const SavingsClubType = PropTypes.shape({
  active: PropTypes.arrayOf(SavingsClubOfferType),
  awaiting_approval: PropTypes.arrayOf(SavingsClubOfferType),
  prior: PropTypes.arrayOf(SavingsClubOfferType),
})

export const SavingsClubOfferDetailType = PropTypes.shape({
  account: AccountType,
  offer: SavingsClubOfferType,
  additional_offers: PropTypes.arrayOf(SavingsClubOfferType),
})

export const AllSavingsClubTypes = PropTypes.shape({
  savings_club: SavingsClubType,
})

export const SearchCriteriaType = PropTypes.shape({
  category: PropTypes.string,
  search: PropTypes.string,
  sort: PropTypes.string,
  zip: PropTypes.string,
})

export const ProductPrices = PropTypes.objectOf(PropTypes.number)

export const SearchResult = PropTypes.shape({
  formatted_address: PropTypes.string,
  name: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
})

export const StepLinkType = PropTypes.shape({
  stepName: PropTypes.string,
  path: PropTypes.string,
})
